/** @define informations; weak */
.informations {
  position: relative;
  padding: 20px 0;
  background-color: #ffffff;
}
.informations__boxpic {
  margin-bottom: 20px;
}
@media only screen and (min-width: 800px) {
  .informations__boxpic {
    width: 45%;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1400px) {
  .informations__boxpic {
    width: 610px;
  }
}
@media only screen and (min-width: 800px) {
  .informations__boxcont {
    width: 55%;
    padding: 0 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .informations__boxcont {
    flex: 1;
  }
}
/*# sourceMappingURL=css/informations.css.map */